import axios from "axios"
import router from '../../router'
const API_URL = 'https://homeqor.ozlive.com';
// const GAME_URL = API_URL;
// const DELAY = 2000;
const URL_PARAMS = new URLSearchParams(window.location.search);

const state = {
    oe_data: null,
    bc_data: null,
    gs_data: null,
    resultOeList: [],
    resultBcList: [],
    resultGsList: [],
    tableNumber: '01',
    gameType: null
}
const getters = {
    resultOeList: (state) => {
        return state.resultOeList;
    },
    resultBcList: (state) => {
        return state.resultBcList;
    },
    resultGsList: (state) => {
        return state.resultGsList;
    },
    gameType: (state) => {
        return state.gameType;
    },
    oe_data: (state) => {
        return state.oe_data;
    },
    bc_data: (state) => {
        return state.bc_data;
    },
    gs_data: (state) => {
        return state.gs_data;
    }
}
const actions = {
    getResultOdd({ commit }, details) {
        return new Promise((resolve, reject) => {
            var search = null;
            if (details.input) {
                search = details.input
            }
            let page = details.page_number ? details.page_number : 1
            let url = API_URL + '/home/page/result' + '?page=' + page
            let config = {}
            let form_data = new FormData()
            form_data.append('limit', 15)
            form_data.append('game_type', 1)
            form_data.append('table_no', '01')
            form_data.append('search', search)

            axios.post(url, form_data, config)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setOddEvenData', res.data.data);
                    }
                }).catch((err) => {
                })
        })
    },
    getResultBaccarat({ commit }, details) {
        return new Promise((resolve, reject) => {
            var search = null;
            if (details.input) {
                search = details.input
            }
            let page = details.page_number ? details.page_number : 1
            var isTable = URL_PARAMS.has('table');
            var tableNumber = '01';
            if (isTable == true) {
                tableNumber = router.currentRoute._rawValue.query.table
            }

            let url = API_URL + '/home/page/result' + '?page=' + page
            let config = {}
            let form_data = new FormData()
            form_data.append('limit', 15)
            form_data.append('game_type', 2)
            form_data.append('table_no', tableNumber)
            form_data.append('search', search)

            axios.post(url, form_data, config)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setBaccaratData', res.data.data);
                    }
                }).catch((err) => {
                })
        })
    },
    getResultGostop({ commit }, details) {
        return new Promise((resolve, reject) => {
            let page = details.page_number ? details.page_number : 1
            var search = null;
            if (details.input) {
                search = details.input
            }
            var isTable = URL_PARAMS.has('table');
            var tableNumber = '01';
            let url = API_URL + '/home/page/result' + '?page=' + page
            let config = {}
            let form_data = new FormData()
            form_data.append('limit', 15)
            form_data.append('game_type', 3)
            form_data.append('table_no', '01')
            form_data.append('search', search)

            axios.post(url, form_data, config)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setGoStopData', res.data.data);
                    }
                }).catch((err) => {
                })
        })
    },
    displayResult({ state, commit }, game) {
        return new Promise((resolve, reject) => {
            var isTable = URL_PARAMS.has('table');
            if (isTable == true) {
                state.tableNumber = router.currentRoute._rawValue.query.table
            }
            state.gameType = game.type;
            let url = API_URL + '/home/page/result';
            let config = { header: { 'Content-Type': '' } }
            let form_data = new FormData

            form_data.append('table_no', state.tableNumber)
            form_data.append('page', game.page)
            form_data.append('game_type', game.type)
            form_data.append('stats', game.stats)
            axios.post(url, form_data, config)
                .then((res) => {

                    commit('setStats', res.data.data.stats);

                }).catch((err) => {

                })
        })
    }
}
const mutations = {
    setList(state, data) {
        state.resultOeList = data
    },
    setOddEvenData(state, data) {
        state.oe_data = data
    },
    setBaccaratData(state, data) {
        state.bc_data = data
    },
    setGoStopData(state, data) {
        state.gs_data = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
