<template>
  <footer>
    <div id="footer">
      <div class="container clearfix">
        <div id="nav_menu-5" class="widget widget_nav_menu">
          <h3 class="widgettitle">CATEGORY</h3>
          <div class="menu-footer-menu-container">
            <ul class="menu">
              <li
                id="menu-item-697"
                class="
                  menu-item menu-item-type-custom menu-item-object-custom
                  current-menu-item current_page_item
                  menu-item-home menu-item-697
                "
              >
                <a href="">About Us</a>
              </li>
              <li
                class="
                  menu-item
                  menu-item-type-custom
                  menu-item-object-custom
                  menu-item-1803
                "
              >
                <a href="web/GameOddEven.html">OZ Game's</a>
              </li>
              <li
                class="
                  menu-item
                  menu-item-type-post_type
                  menu-item-object-page menu-item-698
                "
              >
                <a href="web/sthj.html">Game Results</a>
              </li>
              <li
                class="
                  menu-item
                  menu-item-type-post_type
                  menu-item-object-page menu-item-698
                "
              >
                <a href="web/Alliance.html">Alliance Site</a>
              </li>
              <li
                class="
                  menu-item
                  menu-item-type-post_type
                  menu-item-object-page menu-item-699
                "
              >
                <a href="web/Api.html">API Service</a>
              </li>
            </ul>
          </div>
        </div>
        <!-- end .widget -->
        <div id="recent-posts-5" class="widget widget_recent_entries">
          <h3 class="widgettitle">SOCIAL</h3>
          <ul>
            <li>
              <a href="javascript:void(0)">Facebook</a>
            </li>
            <li>
              <a href="javascript:void(0)">Twitter</a>
            </li>
            <li>
              <a href="javascript:void(0)">YouTube</a>
            </li>
          </ul>
        </div>
        <!-- end .widget -->
        <div id="aboutmewidget-2" class="widget widget_aboutmewidget">
          <h3 class="widgettitle">INQUIRIES</h3>

          <p class="email">ozlive.api@gmail.com</p>
        </div>
        <!-- end about me section -->
        <div id="widget_rate" class="widget widget_rate last">
          <ul>
            <li class="showimage">
              <img src="@/assets/image/smart-hand-book-direct-logo.png" />
            </li>
            <li><img src="@/assets/image/best-rate-guarantee_footer.png" /></li>
            <li><img src="@/assets/image/award-four-diamond.png" /></li>
          </ul>
        </div>
        <!-- end about me section -->
      </div>
      <div class="clear"></div>
      <!-- end .widget -->
    </div>
  </footer>
  <section>
    <div id="copyright">
      <div class="container clearfix">
        <p id="copyinfo">Copyright ⓒ Since 2016 OZ Live. All Right Reserved.</p>
        <div id="lan-type">
          <span id="lan-type-text"
            ><img src="@/assets/image/lan_image1.jpg" /> Korea</span
          >
          <ul id="lan-type-select">
            <li rel="english">
              <img src="@/assets/image/lan_image.png" /> English
            </li>
            <li rel="korea" class="select">
              <img src="@/assets/image/lan_image1.jpg" /> Korea
            </li>
            <li rel="chinese">
              <img src="@/assets/image/lan_image3.jpg" /> Chinese
            </li>
          </ul>
        </div>
      </div>
      <!-- end #container -->
    </div>
  </section>
</template>



<script>
import $ from "jquery";

const ENVIRONMENT = process.env.NODE_ENV;

export default {
  mounted() {},
};
</script>

