import { createStore, createLogger } from 'vuex'
// import resultbc from './module/resultbc'
import result from './module/result'
import alliance from './module/alliance'

export default createStore({
  modules: {
    plugins: [createLogger()],
    // resultbc,
    result,
    alliance
  }
})
