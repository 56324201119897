import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
		path: '/',
		name: 'Home',
		component: () => import(/* webpackChunkName: "web_live2" */ '../views/Home.vue')
	},
	{
		path: '/error.html',
		name: 'Error',
		component: () => import(/* webpackChunkName: "web_live2" */ '../views/Error.vue')
	},
    {
		path: '/web/GameOddEven.html',
		name: 'Gameoe',
		alias: ['/web/GameOddEven.html'],
		component: () => import(/* webpackChunkName: "web_live2" */ '../views/Gameoe.vue')
	},
	{
		path: '/web/GameBaccarat.html',
		name: 'Gamebc',
		alias: ['/web/GameBaccarat.html'],
		meta :{
			table: [1,2,3]
		},
		component: () => import(/* webpackChunkName: "web_live2" */ '../views/Gamebc.vue')
	},
	{
		path: '/web/GameGoStop.html',
		name: 'Gamegs',
		alias: ['/web/GameGoStop.html'],
		component: () => import(/* webpackChunkName: "web_live2" */ '../views/Gamegs.vue')
	},
	{
		path: '/web/sthj.html',
		name: 'Resultoe',
		alias: ['/web/sthj.html'],
		component: () => import(/* webpackChunkName: "web_live2" */ '../views/Resultoe.vue')
	},
	{
		path: '/web/stbc.html',
		name: 'Resultbc',
		alias: ['/web/stbc.html'],
		meta :{
			table: [1,2,3]
		},
		component: () => import(/* webpackChunkName: "web_live2" */ '../views/Resultbc.vue')
	},
	{
		path: '/web/stgs.html',
		name: 'Resultgs',
		alias: ['/web/stgs.html'],
		component: () => import(/* webpackChunkName: "web_live2" */ '../views/Resultgs.vue')
	},
	{
		path: '/web/Alliance.html',
		name: 'Alliance',
		alias: ['/web/Alliance.html'],
		component: () => import(/* webpackChunkName: "web_live2" */ '../views/Alliance.vue')
	},
	{
		path: '/web/Api.html',
		name: 'Apioe',
		alias: ['/web/Api.html'],
		component: () => import(/* webpackChunkName: "web_live2" */ '../views/Apioe.vue')
	},
	{
		path: '/web/Api_B.html',
		name: 'Apibc',
		alias: ['/web/Api_B.html'],
		component: () => import(/* webpackChunkName: "web_live2" */ '../views/Apibc.vue')
	},
	{
		path: '/web/Api_G.html',
		name: 'Apigs',
		alias: ['/web/Api_G.html'],
		component: () => import(/* webpackChunkName: "web_live2" */ '../views/Apigs.vue')
	}
	// {
	// 	path: '/lgs',
	// 	name: 'lgs',
	// 	component: () => import(/* webpackChunkName: "web_live2" */ '../views/Lgs.vue')
	// },
	// {
	// 	path: '/lbc',
	// 	name: 'lbc',
	// 	component: () => import(/* webpackChunkName: "web_live2" */ '../views/Lbc.vue')
	// },
	// {
	// 	path: '/loe',
	// 	name: 'loe',
	// 	component: () => import(/* webpackChunkName: "web_live2" */ '../views/Loe.vue')
	// },
	// {
	// 	path: '/dgs',
	// 	name: 'dgs',
	// 	component: () => import(/* webpackChunkName: "web_live2" */ '../views/Dgs.vue')
	// },
	// {
	// 	path: '/dbc',
	// 	name: 'dbc',
	// 	component: () => import(/* webpackChunkName: "web_live2" */ '../views/Dbc.vue')
	// },
	// {
	// 	path: '/doe',
	// 	name: 'doe',
	// 	component: () => import(/* webpackChunkName: "web_live2" */ '../views/Doe.vue')
	// }
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
