<template>
  <header style="position: relative; z-index: 6">
    <div id="header">
      <a href="/" class="logo-mobile"
        ><img src="@/assets/image/logo.png" class="logo"
      /></a>
    </div>
    <nav>
      <div id="menu">
        <a href="javascript:void(0)" id="menu-open">
          <div class="menu_icon"><span></span><span></span><span></span></div>
          <span></span>
        </a>
        <ul id="toggle">
          <li><a href="/">About Us</a></li>

          <!-- <div id="nav-games-holder"> -->
          <li class="mgm-games desktop">
            <a href="javascript:void(0)" class="aaaa">OZ Games</a>
            <ul class="show-games">
              <li id="livecasinogame"><i>OZ Live Casino Games</i></li>
              <li id="games">
                <a href="/web/GameOddEven.html">> OZ Odd Even (1)</a>
              </li>
              <li id="games">
                <a href="/web/GameBaccarat.html?table=01">> OZ Baccarat (3)</a>
              </li>
              <li id="games">
                <a href="/web/GameGoStop.html">> Go Stop (1)</a>
              </li>
              <!-- <li id="games"><a href="web/GameNine.html"> > OZ Nine (1)</a></li> -->
            </ul>
          </li>
          <!-- </div> -->
          <li class="mobile">
            <a href="/web/GameOddEven.html">OZ Odd Even (1)</a>
          </li>
          <li class="mobile">
            <a href="/web/GameBaccarat.html?table=01">OZ Baccarat (3)</a>
          </li>
          <li class="mobile"><a href="/web/GameGoStop.html">Go Stop (1)</a></li>
          <li class="mobile">
            <router-link to="/web/sthj.html">Results</router-link>
          </li>
          <li class="mobile">
            <router-link to="/web/Api.html">Api</router-link>
          </li>

          <li class="mgm-games desktop">
            <a href="javascript:void(0)" class="aaaa">Game Results</a>
            <ul class="show-games">
              <li id="livecasinogame"><i>OZ Live Casino Games</i></li>
              <li id="games">
                <router-link to="/web/sthj.html">> OZ Odd Even</router-link>
              </li>
              <li id="games">
                <router-link to="/web/stbc.html?table=01"
                  >> OZ Baccarat</router-link
                >
              </li>
              <li id="games">
                <router-link to="/web/stgs.html">> OZ Go Stop</router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/web/Alliance.html">Alliance Site</router-link>
          </li>
          <li class="mgm-games desktop">
            <a href="javascript:void(0)" class="aaaa">API Service</a>
            <ul class="show-games">
              <li id="livecasinogame"><i>OZ Live Casino Games</i></li>
              <li id="games">
                <router-link to="/web/Api.html">> OZ Odd Even</router-link>
              </li>
              <li id="games">
                <router-link to="/web/Api_B.html">> OZ Baccarat</router-link>
              </li>
              <li id="games">
                <router-link to="/web/Api_G.html">> OZ Go Stop</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>



<script>
import $ from "jquery";

const ENVIRONMENT = process.env.NODE_ENV;

export default {};
</script>

<style scoped>
.video-active {
  z-index: 110;
  opacity: 10 !important;
}
.notice-inactive {
  display: none !important;
}

.notice-active {
  display: block !important;
}
#label-provider {
  display: none;
}
.container-label-provider {
  text-align: center;
  margin-top: 35px;
}
.desktop-info-provider {
  width: 910px !important;
}
.box-mobile-provider {
  display: none;
}
#game-alliance {
  height: 360px;
}
.game-alliance-logo {
  padding-top: 40px;
}

.live-page-info-provider {
  margin-top: 60px;
}

.box-game {
  position: relative;
  z-index: 4;
}

.container-nine .nine-players-cards > .box-nine-game {
  position: relative;
}

.container-nine .nine-players-cards .nine-totals {
  margin-top: 33px;
  margin-left: 10px;
  position: absolute;
}

.game-page > * {
  display: inline-block;
  vertical-align: middle;
}

.game-page > span {
  margin-top: -3px;
}

.game-page > .box-table-page {
  margin-top: -3px;
}

.game-page > .box-table-page > a {
  color: black;
  width: 29px;
  height: 20px;
  line-height: 18px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #d6d6d6;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  border-right: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  background-color: #ddd;
  text-align: center;
  font-size: 10px;
  display: inline-block;
  float: left;
}

.game-page > .box-table-page > a.active {
  background-color: #006666;
  color: white;
  border: 1px solid #4caf50;
}

.ads-mobile-head {
  position: relative;
}

.ads-mobile-head > .box-table-page {
  float: left;
  position: absolute;
  left: 8px;
  top: 17px;
}

.ads-mobile-head > .box-table-page > a {
  color: black;
  width: 25px;
  height: 20px;
  line-height: 18px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #d6d6d6;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  border-right: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  background-color: #ddd;
  text-align: center;
  font-size: 10px;
  display: inline-block;
  float: left;
}

.ads-mobile-head > .box-table-page > a.active {
  background-color: #006666;
  color: white;
  border: 1px solid #4caf50;
}

#featured-thumbs .container {
  width: 440px;
}

#featured-thumbs .et_thumb_small:nth-child(3) {
  background: #ffffff url(../assets/image/small_gs_off.jpg) 4px 4px no-repeat;
  background-size: 72px 72px;
}
#featured-thumbs .et_thumb_small.hover:nth-child(3) {
  background: #ffffff url(../assets/image/small_gs_on.jpg) 4px 4px no-repeat;
  background-size: 72px 72px;
}

#featured-slider .slide4_thumb {
  background: #fff url(../assets/image/big_gs.jpg) 8px 8px no-repeat;
  background-size: 354px 234px;
}

/*#featured-thumbs .et_thumb_small:first-child{
        margin-left: 65px;
    }*/

#featured-thumbs .et_thumb_small {
  margin-left: 50px;
}

.gostop-box {
  background-color: #cc2d7a;
  width: 15px;
  height: 15px;
  /*margin-left: -3px;*/
}

/*.box-iframe-52{
        position: relative;
        z-index: -1;
    }*/

#menu ul {
  padding-left: 12px;
}

.game-category {
  margin-left: 25px;
}

.desktop-stats {
  display: inline-block;
}

.mobile-stats {
  display: none;
}

.banner-box {
  margin-right: 40px;
  padding-left: 30px;
}
@media (max-width: 940px) {
  .desktop-stats {
    display: none;
  }

  .mobile-stats {
    display: block;
  }

  #resultL .content {
    overflow-y: hidden;
    overflow-x: hidden;
    text-align: center;
  }
  #menu ul {
    padding-left: 0px;
  }

  #featured-thumbs .et_thumb_small {
    margin-left: 10px;
  }

  #menu ul li:nth-child(10) {
    background: #dfdfdf url(../assets/image/menu_li_bg1.png) 22px 16px no-repeat;
    background-size: 23px 25px;
  }
  #featured-thumbs .container {
    width: 90px !important;
  }

  #featured-thumbs .et_thumb_small:nth-child(3) {
    background: transparent url(../assets/image/mobile_bullet/bullet.png) 4px
      4px no-repeat;
    background-size: 12px 12px;
  }

  #featured-thumbs .et_thumb_small:nth-child(3).hover {
    background: transparent url(../assets/image/mobile_bullet/bullet-hover.png)
      4px 4px no-repeat;
    background-size: 12px 12px;
  }

  #featured-slider .slide4_thumb {
    background: url(../assets/image/big_gs.jpg) center center no-repeat;
  }
  .box-desktop-provider {
    display: none;
  }
  .box-mobile-provider {
    display: block;
  }
  .mobile-info-provider {
    width: 100% !important;
    margin-left: -35px;
  }

  .page-nav-game-mobile {
    height: 25px !important;

    display: block;
  }

  .mobile-page-nav > .game-category {
    float: none;
    text-align: center;
    margin: 0;
    width: 31%;
    height: 20px;
  }

  .container-nine .nine-players-cards > .box-nine-game {
    position: relative;
  }
  .container-nine .nine-players-cards .nine-totals {
    /*margin-top: 0px;
                margin-left: 0px; 
                margin-top: -130px; 
            margin-left: -65px;*/
    margin-top: -247px;
    margin-left: 5px;
    position: relative;
  }
}
@media (max-width: 414px) {
  #label-provider {
    display: block;
    height: 10px;
  }
}
</style>

