import axios from "axios"
import router from '../../router'
const API_URL = 'https://30allyback.ozlive.com/';
// const GAME_URL = API_URL;
// const DELAY = 2000;
// const URL_PARAMS = new URLSearchParams(window.location.search);

const state = {
    allianceList: null,
    allianceBc: null,
    allianceOe: null,
    allianceGs: null,
    allianceRandom: null,
}
const getters = {
    allianceList: (state) => {
        return state.allianceList;
    },
    allianceBc: (state) => {
        return state.allianceBc;
    },
    allianceOe: (state) => {
        return state.allianceOe;
    },
    allianceGs: (state) => {
        return state.allianceGs;
    },
    allianceRandom: (state) => {
        return state.allianceRandom;
    }
}
const actions = {
    getAlliance({ commit }, page_number) {
        return new Promise((resolve, reject) => {

            let page = page_number ? page_number : 1

            let url = API_URL + 'home/page/alliance'
            let config = {}
            let form_data = new FormData()
            axios.post(url, form_data, config)

                .then((res) => {
                    if (res.status == 200) {
                        commit('setList', res.data.data);
                        commit('setBaccarat', res.data.data.baccarat);
                        commit('setOddeven', res.data.data.odd_even);
                        commit('setGostop', res.data.data.go_stop);
                    }
                }).catch((err) => {
                })
        })
    },
    getRandom({ commit }, page_number) {
        return new Promise((resolve, reject) => {
            let page = page_number ? page_number : 1
            let url = API_URL + 'home/page/banner'
            let config = {}
            let form_data = new FormData()

            axios.post(url, form_data, config)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setListRandom', res.data);
                    }
                }).catch((err) => {
                })
        })
    }
}
const mutations = {
    setList(state, data) {
        state.allianceList = data
    },
    setListRandom(state, data) {
        state.allianceRandom = data
    },
    setBaccarat(state, data) {
        state.allianceBc = data
    },
    setOddeven(state, data) {
        state.allianceOe = data
    },
    setGostop(state, data) {
        state.allianceGs = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
